<template>
  <div class="log-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="操作日志" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 帐号 -->
        <ml-input
          prop="userCodeLike"
          placeholder="请输入账号"
          style="margin-bottom: 0"
          label="帐号："
          v-model="searchData.userCodeLike"
        />
        <!-- 操作类型 -->
        <ml-input
          prop="searchName"
          placeholder="请输入操作类型"
          style="margin-bottom: 0"
          label="操作类型"
          v-model="searchData.searchName"
        />
        <!-- 日期 -->
        <ml-date-picker
          prop="date"
          startPlaceholder="开始日期"
          endPlaceholder="结束日期"
          label="操作时间:"
          type="datetimerange"
          v-model="searchData.date"
          :style="{ 'margin-bottom': 0, 'margin-right': '40px' }"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="导出"
          submitIcon="el-icon-download"
          @click-submit="clickExport"
          :showCancel="false"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="登录账号" prop="loginName" min-width="10%" />
        <el-table-column align="center" label="阿米巴" prop="companyName" min-width="10%" />
        <el-table-column align="center" label="操作类型" prop="operateName" min-width="20%" />
        <el-table-column align="center" label="操作内容" prop="operateDesc" min-width="30%" />
        <!-- <el-table-column align="center" label="操作类型" prop="logType" min-width="10%" /> -->
        <el-table-column align="center" label="操作时间" prop="createTime" min-width="10%" />
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  formetData,
  getUrlParamsString,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { BASEURL, prefix } from '@API'

export default {
  name: 'LogManage',
  setup() {
    const { dispatch, getters } = useStore()
    const toKen = computed(() => getters.getToken)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      userCodeLike: '',
      searchName: '',
      date: []
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'])
      if (searchData.date.length > 0) {
        searchDataParams.startTime = formetData(searchData.date[0])
        searchDataParams.endTime = formetData(searchData.date[1])
      }
      searchDataParams.userCodeLike = replacePerCent(searchData.userCodeLike)
      searchDataParams.searchName = replacePerCent(searchData.searchName)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchSysLogInfos',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getLogTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    /* 结束 数据列表 **********************************/
    const clickExport = () => {
      const searchDataParams = searchParams(searchData, ['date'])
      if (searchData.date.length > 0) {
        searchDataParams.startTime = formetData(searchData.date[0])
        searchDataParams.endTime = formetData(searchData.date[1])
      }
      const paramsUrl = getUrlParamsString(searchDataParams)
      const url = paramsUrl
        ? `/v1/op/sysLogExport?${paramsUrl}&token=${toKen.value}`
        : `/v1/op/sysLogExport?token=${toKen.value}`
      window.location.href = `${BASEURL}${prefix}${url}`
    }
    onMounted(() => {
      getLogTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickExport
    }
  }
}
</script>

<style lang="scss" scoped>
.log-manage {
  @extend %params-global;
}
</style>
